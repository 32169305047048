import React, {
    useCallback,
    useContext,
    useMemo,
    useReducer,
    Dispatch,
    createContext,
} from 'react'
import { v4 as uuid } from 'uuid'

import omitBy from 'lodash/omitBy'

import {
    createTransfer as apiCreateTransfer,
    deleteTransfer as apiDeleteTransfer,
} from './firebase'
import { debugPrint, logAnalyticsEvent } from '../utils/helpers'

interface TransfersState {
    inProgress: boolean
    transferErrorMessage?: string
}

const initialState = {
    inProgress: false,
}

type TransfersAction =
    | { type: 'TRANSFER_STARTED' }
    | { type: 'TRANSFER_COMPLETED' }
    | { type: 'TRANSFER_FAILED'; payload: Error }
    | { type: 'DELETE'; payload: string }


interface CreateTransferData {
    accessToken: string,
    amount: string,
    description: string,
    email?: string,
    legalName: string,
    orderId: string,
    uid: string,
    plaidAccountId: string
}

interface TransfersContextShape extends TransfersState {
    createTransfer: (data: CreateTransferData) => Promise<void>
    deleteTransfers: (userId: string) => Promise<void>
    dispatch: Dispatch<TransfersAction>
    transfers: TransfersState
}

const TransfersContext = createContext<TransfersContextShape>(initialState as TransfersContextShape)

/**
 * @desc Maintains the Transfers context state.
 */
export function TransfersProvider(props: { children: React.ReactNode }) {
    const [transfers, dispatch] = useReducer(reducer, initialState)

    const createTransfer = useCallback(async ({ accessToken, amount, description, email, legalName, orderId, plaidAccountId, uid }: CreateTransferData) => {
        try {
            dispatch({ type: 'TRANSFER_STARTED' })

            await apiCreateTransfer({
                accessToken,
                amount,
                description,
                email,
                legalName,
                idempotencyKey: uuid(),
                orderId,
                plaidAccountId,
                uid,
            })
            dispatch({ type: 'TRANSFER_COMPLETED' })
        } catch (error) {
            logAnalyticsEvent({ type: 'failed-to-create-transfer', message: JSON.stringify(error) })
            debugPrint(`apiCreateTransfer failed for user ${uid} with error: ${JSON.stringify(error)}`, 'error')
            dispatch({ type: 'TRANSFER_FAILED', payload: error as Error })
        }
    }, [])

    /**
     * @desc Will delete all transfers that belong to an individual User.
     */
    const deleteTransfers = useCallback(async (userId: string) => {
        await apiDeleteTransfer(userId)
        dispatch({ type: 'DELETE', payload: userId })
    }, [])

    const value = useMemo(
        () => ({
            ...transfers,
            createTransfer,
            deleteTransfers,
        }),
        [transfers, createTransfer, deleteTransfers]
    )

    return <TransfersContext.Provider value={value} {...props} />
}

/**
 * @desc Handles updates to the Transfer state as dictated by dispatched actions.
 */
function reducer(state: any, action: TransfersAction) {
    switch (action.type) {
        case 'TRANSFER_STARTED':
            return {
                ...state,
                inProgress: true,
                transferErrorMessage: undefined,
            }
        case 'TRANSFER_COMPLETED':
            return {
                ...state,
                inProgress: false,
                transferErrorMessage: undefined,
            }
        case 'TRANSFER_FAILED':
            return {
                ...state,
                inProgress: false,
                transferErrorMessage: "Failed to create the transfer. This is an internal error. Please contact support if the issue persists.",
            }
        case 'DELETE':
            const transfers = state.transfersById
            return {
                ...state,
                transfersById: omitBy(
                    transfers,
                    transfer => transfer.user_id === action.payload
                ),
            }
        default:
            logAnalyticsEvent({ type: 'internal-error', message: `transfers reducer got unknown action: ${JSON.stringify(action)}` })
            debugPrint(`Unknown action: ${JSON.stringify(action)}`, 'warn')
            return state
    }
}

/**
 * @desc A convenience hook to provide access to the Transfer context state in components.
 */
export default function useTransfers() {
    const context = useContext(TransfersContext)
    if (!context)
        throw new Error(`useTransfers must be used within a TransfersProvider`)

    return context
}

import { Navigate, redirect, useLocation, useSearchParams } from "react-router-dom"
import { debugPrint } from "./helpers"

interface ProtectedRouteProps {
  loggedIn: boolean
  children: JSX.Element
  redirectTo?: string
}

const ProtectedRoute = ({ loggedIn, children, redirectTo }: ProtectedRouteProps): JSX.Element => {
  const location = useLocation()
  const [searchParams, _] = useSearchParams()

  if (!loggedIn) {
    const email = searchParams.get('email')
    const plan = searchParams.get('sub_plan')
    const phone = searchParams.get('phone')

    let redirectPath = redirectTo ?? "/login"
    
    if (email) {
      debugPrint(`[ProtectedRoute] Setting user email to ${email}`, 'info')
      localStorage.setItem('ue', email) // user email
    }

    if (plan) {
      debugPrint(`[ProtectedRoute] Setting user plan to ${plan}`, 'info')
      localStorage.setItem('plan', plan) // user plan
    }

    if (phone) {
      debugPrint(`[ProtectedRoute] Setting user phone to ${phone}`, 'info')
      localStorage.setItem('phone', phone) // user phone
    }

    return <Navigate to={redirectPath} replace state={{ from: location }} />
  }

  return children
}

export default ProtectedRoute
import { createContext, ReactNode, useContext, useCallback, useMemo, useReducer } from 'react'
import {
    observeUserData,
    setDefaultAccount as apiSetDefaultAccount,
    removeAccount as apiRemoveAccount,
    setLegalNameAndEmail as apiSetLegalNameAndEmail,
} from './firebase'
import { debugPrint, logAnalyticsEvent } from "../utils/helpers"
import { Dispatch } from '@reduxjs/toolkit'
import { UserDataType } from '../types'

const initialState: UserDataType = {
    accounts: {},
    hasLoaded: false,
    orders: {},
}

type UserDataAction =
    | { type: 'UPDATE_USER_DATA'; payload: UserDataType }
    | { type: 'FAILED_TO_UPDATE_USER_DATA'; payload: UserDataType }
    | { type: 'SET_DEFAULT_ACCOUNT'; payload: string }
    | { type: 'FAILED_TO_SET_DEFAULT_ACCOUNT'; payload: string }
    | { type: 'REMOVED_ACCOUNT'; payload: string }
    | { type: 'FAILED_TO_REMOVE_ACCOUNT'; payload: string }
    | { type: 'WILL_SET_LEGAL_NAME_AND_EMAIL' }
    | { type: 'DID_SET_LEGAL_NAME_AND_EMAIL' }
    | { type: 'FAILED_TO_SET_LEGAL_NAME_AND_EMAIL'; payload: string }


interface UserDataContextShape extends UserDataType {
    dispatch: Dispatch<UserDataAction>
    fetchUserData: (userId: string) => void
    removeAccount: (accountId: string) => Promise<void>
    setDefaultAccount: (accountId: string) => Promise<void>
    setLegalNameAndEmail: ({ legalName, email }: { legalName: string, email?: string }) => Promise<void>
    userData: UserDataType
}

const UserDataContext = createContext<UserDataContextShape>(initialState as UserDataContextShape)

function reducer(state: UserDataType, action: UserDataAction) {
    switch (action.type) {
        case 'WILL_SET_LEGAL_NAME_AND_EMAIL':
            return {
                ...state,
            }
        case 'DID_SET_LEGAL_NAME_AND_EMAIL':
            return {
                ...state,
            }
        case 'FAILED_TO_SET_LEGAL_NAME_AND_EMAIL':
            return {
                ...state,
                error: action.payload,
            }
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                ...action.payload,
            }
        default:
            debugPrint('unknown action', 'warn')
            return state
    }
}

export function UserDataProvider(props: { children: ReactNode }) {
    const [userData, dispatch] = useReducer(reducer, initialState)

    const setDefaultAccount = useCallback(async (accountId: string): Promise<void> => {
        try {
            await apiSetDefaultAccount(accountId)
            dispatch({ type: 'SET_DEFAULT_ACCOUNT', payload: accountId })
        } catch (error) {
            logAnalyticsEvent({ type: 'server-error', message: `setDefaultAccount failed with error: ${JSON.stringify(error)}` })
            debugPrint(JSON.stringify(error), 'error')
            dispatch({ type: 'FAILED_TO_SET_DEFAULT_ACCOUNT', payload: (error as Error).message })
        }
    }, [])

    const removeAccount = useCallback(async (accountId: string): Promise<void> => {
        try {
            await apiRemoveAccount(accountId)
            dispatch({ type: 'REMOVED_ACCOUNT', payload: accountId })
        } catch (error) {
            logAnalyticsEvent({ type: 'server-error', message: `removeAccount failed with error: ${JSON.stringify(error)}` })
            debugPrint(JSON.stringify(error), 'error')
            dispatch({ type: 'FAILED_TO_REMOVE_ACCOUNT', payload: (error as Error).message })
        }
    }, [])

    const setLegalNameAndEmail = useCallback(async ({ legalName, email }: { legalName: string, email?: string }): Promise<void> => {
        try {
            dispatch({ type: 'WILL_SET_LEGAL_NAME_AND_EMAIL' })
            await apiSetLegalNameAndEmail(legalName, email)
            dispatch({ type: 'DID_SET_LEGAL_NAME_AND_EMAIL' })
        } catch (error: any) {
            logAnalyticsEvent({ type: 'server-error', message: `setLegalNameAndEmail failed with error: ${JSON.stringify(error)}` })
            debugPrint(JSON.stringify(error), 'error')
            dispatch({ type: 'FAILED_TO_SET_LEGAL_NAME_AND_EMAIL', payload: error.message })
        }
    }, [])

    const fetchUserData = useCallback((userId: string) => {
        const onUpdate = (data: UserDataType) => {
            dispatch({ type: 'UPDATE_USER_DATA', payload: data })
        }

        const cancellable = observeUserData(userId, onUpdate)
        return () => cancellable()
    }, [])

    const value = useMemo(
        () => ({
            fetchUserData,
            removeAccount,
            setDefaultAccount,
            setLegalNameAndEmail,
            userData,
        }),
        [userData, setLegalNameAndEmail, fetchUserData]
    )

    // @ts-ignore
    return <UserDataContext.Provider value={value} {...props} />
}

export const useUserData = () => {
    const context = useContext(UserDataContext)
    if (!context) {
        throw new Error('useUserData must be used within a UserDataProvider')
    }
    return context
}
import { getAnalytics, logEvent } from "firebase/analytics"


type LogLevel = 'debug' | 'info' | 'warn' | 'error'


export function debugPrint(message: string, level: LogLevel = 'debug'): void {
    if (process.env.REACT_APP_PROJECT_ID === 'parlay-p-sports-newsletter') return

    switch (level) {
        case 'debug':
            console.debug(message)
            break
        case 'info':
            console.info(message)
            break
        case 'warn':
            console.warn(message)
            break
        case 'error':
            console.error(message)
            break
        default:
            console.log(message)
    }
}


type AnalyticsEventType =
    // auth
    | 'phone-number-invalid'
    | 'phone-number-missing'
    | 'phone-validation'
    | 'otp-not-received'
    | 'otp-missing'
    | 'otp-invalid'
    | 'otp-expired'
    | 'unhandled-auth-error'
    // dashboard
    | 'server-error'
    | 'internal-error'
    | 'config-error'
    | 'plaid-error'
    | 'plaid-link-token-error'
    | 'plaid-link-exit-early'
    | 'failed-to-create-order'
    | 'failed-to-create-transfer'
    | 'failed-to-add-account'
    | 'failed-to-remove-account'
    | 'failed-to-set-default-account'
    | 'failed-to-set-legal-name'
    | 'failed-to-update-item-state'

interface LogEventProps {
    type: AnalyticsEventType
    message: string
}


export function logAnalyticsEvent({ type, message }: LogEventProps): void {
    const analytics = getAnalytics()
    if (analytics)
        logEvent(analytics, type, { message })
    else
        debugPrint(`analytics is not set, could not log: ${type} - ${message}`, 'error')
}
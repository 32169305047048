import { logOut } from "./utils/authListener"
import { ReactComponent as BubbleIcon } from "./assets/images/bubble.svg"
import { ReactComponent as ClipboardIcon } from "./assets/images/clipboard.svg"
import { ReactComponent as XMark } from "./assets/images/xmark.svg"
import { ReactComponent as PowerIcon } from "./assets/images/power.svg"

const Footer: React.FC = () => {
    const closeAccountHandler = () => {
        if (window.confirm("Our support team can help close your account. Do you want to proceed?"))
            window.location.href = "https://www.parlayp.com/support/"
    }

    return (<div className="container py-5 d-flex flex-column flex-md-row justify-content-between flex-shrink-0" id="footer">
        <div className="text-center text-md-start">
            <a href="https://www.parlayp.com/support/" target="_blank" className="btn btn-text btn-sm px-3 text-white"><BubbleIcon className="me-2" /> Contact Support</a>
            |
            <a href="https://www.parlayp.com/terms-conditions/" target="_blank" className="btn btn-text btn-sm px-3 text-white"><ClipboardIcon className="me-2" /> Terms & Conditions</a>
        </div>
        <a href="https://www.thesportsnewsletter.com/" className="btn btn-text btn-sm px-4 text-white d-none d-md-block" target="_blank" rel="noopener noreferrer">© 2024 <u>The Sports Newsletter, LLC</u></a>
        <div className="text-center text-md-start">
            <button onClick={closeAccountHandler} className="btn btn-text text-white btn-sm px-3"><XMark className="me-2" /> Close Account</button>
            |
            <button onClick={logOut} className="btn btn-text text-white btn-sm px-3"><PowerIcon className="me-2" /> Log Out</button>
        </div>
        <a href="https://www.thesportsnewsletter.com/" className="btn btn-text btn-sm px-4 text-white my-5 d-md-none" target="_blank" rel="noopener noreferrer">© 2024 <u>The Sports Newsletter, LLC</u></a>
    </div>)
}

export default Footer